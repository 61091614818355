<template>
  <section class="head">
    <div
      class="bg"
      :style="{ backgroundImage: 'url(' + projectData.coverImg + ')' }"
    ></div>
    <div class="content">
      <div class="cover">
        <img :src="projectData.coverImg" alt="" />
      </div>
      <div class="text">
        <h1>{{ projectData.taskName }}</h1>
        <p>
          <ClockCircleOutlined />
          <template v-if="projectData.startTime > 0">
            <span style="margin-left: 8px">
              {{ dateFormat(projectData.startTime, "YYYY-MM-DD") }} ~
              {{ dateFormat(projectData.endTime, "YYYY-MM-DD") }}
            </span>
          </template>
          <template v-else> {{ $t('subject.no_study_time_limit') }} </template>
          <!-- 无学习时间限制 -->
        </p>
        <p>
          <span style="margin-right: 24px">{{ $t('subject.stages', [stagesData.length]) }}</span>
          <!-- 个阶段 -->
          <span>{{ $t('subject.course_total', [courseTotal]) }}</span>
          <!-- 共 门课程-->
        </p>
        <p>{{ $t('CM_CCondition') }}：{{ $t('subject.learning_progress_reaches', [projectData.learnPass]) }}</p>
        <!-- 完成条件：学习进度达到 -->
        <p>
          <!-- 状态： -->
          {{ $t('CM_Status') }}：<template v-if="projectData.complete == 1">{{ $t('CM_Completed') }}</template>
          <!-- 已完成 -->
          <template
            v-else-if="
              projectData.complete == 3 || projectData.remainderDay < 0
            "
            >{{ $t('project.overdue_not_completed') }}</template
          >
          <!-- 逾期未完成 -->
          <template v-else>{{ $t('CM_NotCompleted') }}</template>
          <!-- 未完成 -->
        </p>
        <p class="progress">
          <a-progress
            :percent="projectData.progress"
            size="small"
            status="success"
            :showInfo="false"
            style="width: 260px; color: #ffffff"
          />
          <span>{{ $t('subject.learned', [projectData.progress]) }}</span>
          <!-- 已学习 -->
        </p>
        <div class="btns">
          <a-button
            v-if="projectData.complete != 3 && projectData.remainderDay >= 0"
            type="primary"
            @click="toStudy(0)"
            >{{ $t('LB_Course_StartStudy') }}</a-button
          >
          <!-- 开始学习 -->
          <div
            v-else
            style="
              padding: 10px 50px;
              background: #aaa;
              border-radius: 4px;
              font-size: 20px;
              cursor: pointer;
            "
            @click="toStudy(1)"
          >
            {{ $t('CM_Expired') }}
            <!-- 已过期 -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mian">
    <a-spin :spinning="loadding">
      <div class="detail">
        <a-tabs>
          <!-- 详情介绍 -->
          <a-tab-pane key="1" :tab="$t('project.details')">
            <div class="content">
              <p class="mark" style="margin-top: 0px">
                <em class="line"></em> {{ $t('subject.special_introduction') }}
                <!-- 专题简介 -->
              </p>
              <div
                v-if="projectData.intro"
                v-html="projectData.intro"
                style="margin-bottom: 20px"
              ></div>
              <div v-else style="margin: 40px 0">
                <a-empty :description="$t('LB_NoIntroduction')" />
                <!-- 暂无介绍 -->
              </div>
              <template
                v-if="
                  projectData &&
                  projectData.certificateName != '' &&
                  projectData.certificateImg != ''
                "
              >
                <p class="mark">
                  <em class="line"></em> {{ projectData.certificateName }}
                </p>
                <div class="certificate">
                  <div class="mask" v-if="projectData.complete != 1">
                    {{ $t('Pub_Msg_NotGet') }}
                    <!-- 未获得 -->
                  </div>
                  <a-image class="image" :src="projectData.certificateImg" />
                </div>
              </template>
            </div>
          </a-tab-pane>
          <!-- 目录 -->
          <a-tab-pane key="2" :tab="$t('LB_Course_Cate')">
            <div class="directory" v-if="stagesData.length">
              <div
                v-for="(stage, index) in stagesData"
                :key="stage.stageId"
                :id="'stage' + (index + 1)"
              >
                <div class="title">
                  <div class="headline">
                    <h5>
                      {{ stagesData[index].stageName }}
                    </h5>
                    <span class="schedule">
                      {{ stagesData[index].progress }}%
                    </span>
                  </div>
                  <div class="intro" v-if="stagesData[index].intro">
                    {{ stagesData[index].intro }}
                  </div>
                </div>
                <ul class="list">
                  <li
                    v-for="item in stagesData[index].details"
                    :key="item.detailId"
                  >
                    <div class="index">{{ item.index }}</div>
                    <img
                      class="courseCover"
                      :src="item.course.coverImg || '//data-cdn.51-learning.cn/default/cover/1.jpg'"
                    />
                    <div class="info">
                      <h5>{{ item.detailName }}</h5>
                      <div class="intro" v-html="item.course.intro" />
                      <div class="lecturers">
                        <span
                          style="margin-right: 12px"
                          v-if="
                            item.course.lecturers &&
                            item.course.lecturers.length > 0
                          "
                        >
                          <span
                            v-for="lecturer in item.course.lecturers"
                            :key="lecturer.id"
                            style="margin-right: 12px"
                          >
                            <img
                              :src="lecturer.portrait"
                              v-if="lecturer.portrait != ''"
                            />
                            <img src="../../assets/image/avatar.png" v-else />
                            <OpenData
                              type="userName"
                              :openid="lecturer.name"
                              v-if="lecturer.lecturerType == 1"
                            /><span v-else>{{ lecturer.name }}</span>
                          </span>
                        </span>
                        <span>{{ $t('subject.nth_people_have_learned', [item.course.learnUsers]) }}</span>
                        <!-- 人已学习 -->
                      </div>
                    </div>
                    <div class="btns">
                      <div style="text-align: center">
                        <div v-if="item.complete == 1" class="green">
                          <CheckCircleFilled /> <span>{{ $t('CM_Completed') }}</span>
                          <!-- 已完成 -->
                        </div>
                        <div v-else-if="item.complete == 2" class="red">
                          <CloseCircleFilled /> <span>{{ $t('CM_NotCompleted') }}</span>
                          <!-- 未完成 -->
                        </div>
                      </div>
                      <template v-if="item.learnFlag">
                        <a-button
                          v-if="
                            projectData.complete != 3 &&
                            projectData.remainderDay >= 0
                          "
                          type="primary"
                          ghost
                          @click="clickJump(item, stagesData[index].stageId)"
                          >{{ $t('view_detail') }}</a-button
                        >
                        <!-- 查看详情 -->
                        <a-button
                          v-else
                          type="primary"
                          style="color: #999999; border-color: #999"
                          ghost
                          @click="clickJump(item, stagesData[index].stageId, 1)"
                          >{{ $t('CM_Expired') }}</a-button
                        >
                        <!-- 已过期 -->
                      </template>
                      <template v-else>
                        <a-button
                          type="primary"
                          style="color: #999999; border-color: #999"
                          ghost
                          @click="clickJump(item, stagesData[index].stageId)"
                          >{{ $t('not_unlocked') }}</a-button
                        >
                        <!-- 未解锁 -->
                      </template>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </a-tab-pane>
          <!-- 评论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="$t('LB_Doc_Comments') + '（' + commentParams.Total + '）'"
            forceRender
          >
            <div style="padding: 14px 30px 10px 30px">
              <my-comment
                v-if="commentParams.resourceId > 0"
                :CommentParams="commentParams"
              ></my-comment>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
    <div class="recommend">
      <!-- 推荐专题 -->
      <a-card :title="$t('subject.recommended_subject')" style="width: 290px">
        <template v-if="recommendList.length > 0">
          <a
            :href="`/subject/detail?id=${item.taskId}${
              newWindow == 1 ? '&ddtab=true' : ''
            }`"
            :target="newWindow == 1 ? '_blank' : '_self'"
            class="item"
            v-for="(item, index) in recommendList.slice(0, 5)"
            :key="index"
          >
            <img :src="item.coverImg" class="coverImg" />
            <p class="title">{{ item.taskName }}</p>
          </a>
        </template>
        <a-empty v-else description="空空如也" />
        <!-- 空空如也 -->
      </a-card>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, getCurrentInstance, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import { subjectDetail, subjectRecommend, subjectStart } from "@/api/subject";
import myComment from "@/components/my-comment";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    "my-comment": myComment,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = Number(route.query.id);

    let loadding = ref(true),
      hasComment = ref(false),
      projectData = ref({}),
      stagesData = ref([]),
      currentStageId = ref(0),
      currentCourseId = ref(0),
      courseTotal = ref(0),
      commentParams = ref({
        page: 1,
        pageSize: 10,
        resourceId: taskId || 0,
        type: 2, //0知识 1课程 2任务 3新闻
        taskType: 38, //任务类型参数 34培训项目 35培训包 37新员工 38课程专题
        Total: 0,
      });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        hasComment.value = company.useComment == 1;
      },
      { immediate: true }
    );

    subjectDetail(taskId).then((res) => {
      loadding.value = false;
      if (res.ret === 0) {
        let d = res.data,
          nowTime = new Date().getTime();
        d.remainderDay =
          d.endTime > 0
            ? Math.floor((d.endTime * 1000 - nowTime) / 86400000)
            : 1;
        // 加入学习
        if (res.data.isBuy == 0) {
          subjectStart(res.data.taskId).then((r) => {});
        }
        projectData.value = res.data;
        stagesData.value = res.data.stages || [];
        // 给每个资源增加index值
        stagesData.value.map((item, i) => {
          if (currentCourseId.value == 0) {
            currentStageId.value = item.stageId;
          }
          // 未逾期  -- 逾期后可学 isOverdue 1 可学
          if ((d.complete != 3 && d.remainderDay >= 0) || d.isOverdue === 1) {
            //阶段是否可学
            if (d.learnOrder == 2) {
              item.learnFlag = true;
            } else {
              if (i == 0) {
                item.learnFlag = true;
              } else {
                if (d.stages[i - 1].progress == 100) {
                  item.learnFlag = true;
                }
              }
            }
            item.details = item.details || [];
            item.details.map((el, j) => {
              courseTotal.value++;
              d.index = courseTotal.value;
              if (d.complete != 1 && currentCourseId.value == 0) {
                currentCourseId.value = d.detailId;
              }
              //阶段明细是否可学
              if (item.learnFlag) {
                if (item.learnOrder == 2) {
                  el.learnFlag = true;
                } else {
                  if (j == 0) {
                    el.learnFlag = true;
                  } else {
                    if (item.details[j - 1].progress == 100) {
                      el.learnFlag = true;
                    }
                  }
                }
              }
            });
          } else {
            //阶段是否可学
            if (item.progress == 100) {
              item.learnFlag = true;
            } else {
              if (i == 0) {
                item.learnFlag = true;
              }
            }
            item.details = item.details || [];
            item.details.map((el, j) => {
              courseTotal.value++;
              d.index = courseTotal.value;
              if (d.complete != 1 && currentCourseId.value == 0) {
                currentCourseId.value = d.detailId;
              }
              //阶段明细是否可学
              if (el.progress == 100) {
                el.learnFlag = true;
              }
            });
          }
        });
        // 专题已经学完，重新赋值
        if (currentCourseId.value == 0) {
          currentStageId.value = stagesData.value[0].stageId;
          currentCourseId.value = stagesData.value[0].details[0].detailId;
        }
      } else {
        const msg = res.msg.indexOf('未授权') > -1 ? $t('subject.course_no_permission') : $t('subject.course_not_exist');
        // 抱歉，您没有该课程专题的学习权限，请联系管理员 ：抱歉，您访问的课程专题不存在
        router.replace({
          path: `/error`,
          query: {
            title: msg,
            path: "/subject",
            name: $t('cm_subject'),
            // 课程专题
          },
        });
      }
    });

    const toStudy = (overdue) => {
      if (overdue && projectData.value.isOverdue != 1) {
        return proxy.$message.info($t('subject.overdue_tip'));
        // 专题已过期，无法继续学习
      }
      let currentTime = Date.parse(new Date()) / 1000;
      if (projectData.value.startTime > currentTime) {
        proxy.$message.warning($t('subject.not_start_tip'));
        // 专题未开始不能学习
        return false;
      }
      let urlStr = `/course/detail?taskId=${taskId}&sid=${currentStageId.value}&did=${currentCourseId.value}`;
      if (store.getters.companyInfo.useNewWindow == 1) {
        window.open(urlStr + "&ddtab=true");
      } else {
        window.location.href = urlStr;
      }
    };

    const clickJump = (item, sid, overdue) => {
      if (overdue && projectData.value.isOverdue != 1) {
        return proxy.$message.info($t('subject.overdue_tip'));
        // 专题已过期，无法继续学习
      }
      let currentTime = Date.parse(new Date()) / 1000;
      if (projectData.value.startTime > currentTime) {
        proxy.$message.warning($t('subject.not_start_tip'));
        // 专题未开始不能学习
        return false;
      }
      if (!item.learnFlag) return proxy.$message.warning($t('not_unlocked'));
      // 未解锁
      let urlStr = `/course/detail?taskId=${taskId}&sid=${sid}&did=${item.detailId}`;
      if (store.getters.companyInfo.useNewWindow == 1) {
        window.open(urlStr + "&ddtab=true");
      } else {
        window.location.href = urlStr;
      }
    };

    const recommendList = ref([]);
    subjectRecommend().then((res) => {
      if (res.ret == 0) {
        recommendList.value = res.data || [];
      }
    });

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      dateFormat,
      loadding,
      hasComment,
      projectData,
      stagesData,
      commentParams,
      recommendList,
      toStudy,
      clickJump,
      courseTotal,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  padding: 42px 0;
  position: relative;
  .bg {
    .absolute-full();
    z-index: 1;
    background-size: cover;
    background-position: center;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      bottom: -50px;
      left: -50px;
      background: inherit;
      filter: blur(30px);
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      bottom: -50px;
      left: -50px;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 3;
    }
  }
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    .cover {
      z-index: 4;
      .mixinImgWrap(500px; 226px);
      border-radius: 6px;
    }
    .text {
      z-index: 4;
      width: calc(100% - 534px);
      color: #fff;
      line-height: 1.5;
      position: relative;
      h1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.4;
        color: #fff;
        margin-bottom: 6px;
        word-break: break-all;
      }
      p {
        margin-bottom: 6px;
        font-size: 20px;
        &.progress {
          margin-top: -5px;
          ::v-deep(.ant-progress-status-success .ant-progress-bg) {
            background-color: #3fbd2a;
            height: 10px !important;
          }
          span {
            font-size: 16px;
            vertical-align: -2px;
            margin-left: 13px;
          }
        }
      }
      .btns {
        position: absolute;
        bottom: 10px;
        right: 0;
        margin-top: 16px;
        margin-right: 60px;
        .ant-btn {
          line-height: 50px;
          border: 1px solid #e63450;
          height: 50px;
          width: 160px;
          font-size: 20px;
          border-radius: 6px;
          color: #e63450;
          background: transparent;
          border-color: #e63450;
          padding: 0;
          margin-right: 12px;
        }
        .ant-btn-primary {
          background: #e63450;
          border-color: #e63450;
          color: #fff;
        }
        .ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          color: rgba(0, 0, 0, 0.25);
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
        .ant-btn[disabled],
        .ant-btn[disabled]:hover,
        .ant-btn[disabled]:focus,
        .ant-btn[disabled]:active {
          color: #d9d9d9;
          border-color: #d9d9d9;
        }
      }
    }
  }
}

.mian {
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .detail {
    width: 890px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 24px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    .content {
      padding: 14px 30px 10px 30px;
    }
    .certificate {
      position: relative;
      margin-bottom: 10px;
      .mask {
        position: absolute;
        background: #00000066;
        width: 400px;
        height: 182px;
        text-align: center;
        line-height: 182px;
        font-size: 26px;
        color: #fff;
        border-radius: 4px;
        z-index: 1;
      }
      ::v-deep(.image) {
        width: 400px;
        height: 182px;
        border-radius: 4px;
      }
    }
    .mark {
      font-size: 15px;
      font-weight: bold;
      margin: 12px 0;
      .line {
        width: 5px;
        height: 16px;
        display: inline-block;
        background: @color-theme;
        vertical-align: -2px;
      }
    }
    .directory {
      padding: 14px 30px 10px 30px;
      .title {
        background-color: #f5f5f6;
        padding: 20px 24px;
        .headline {
          .mixinFlex(space-between; center);
          h5 {
            font-size: 16px;
            margin: 0;
            line-height: 1.5;
            width: calc(100% - 50px);
            span {
              padding-right: 16px;
            }
          }
        }
        .intro {
          margin-top: 6px;
          font-size: 14px;
        }
      }
      .list {
        li {
          .mixinFlex(space-between);
          border-bottom: 1px solid #eaf0ee;
          padding: 22px 24px 22px 22px;
          margin-left: 20px;
          border-left: 1px solid #eaf0ee;
          position: relative;
          .index {
            position: absolute;
            left: -11px;
            top: 42%;
            min-width: 18px;
            min-height: 18px;
            background: #dddddd;
            color: #666666;
            font-size: 12px;
            border-radius: 50px;
            text-align: center;
          }
          .courseCover {
            width: 220px;
            height: 100px;
            margin-right: 16px;
          }
          .info {
            width: calc(100% - 60px);
            font-size: 12px;
            h5 {
              font-size: 16px;
              margin: 0;
              .mixinEllipsis(22px);
            }
            .intro {
              color: #666;
              margin-top: 5px;
              .mixinEllipsis(36px, 2);
            }
            .lecturers {
              margin-top: 15px;
              color: #333;
              img {
                width: 16px;
                height: 16px;
                border-radius: 50px;
                vertical-align: -3px;
                margin-right: 4px;
              }
            }
          }
          .btns {
            margin-left: 10px;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            width: 100px;
            ::v-deep(.ant-btn-background-ghost.ant-btn-primary) {
              border-radius: 4px;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  .recommend {
    width: 290px;
    height: 100%;
    margin-top: 24px;
    .item {
      display: inline-block;
      margin-bottom: 20px;
      .coverImg {
        .mixinImgWrap(250px; 114px);
        border-radius: 4px;
      }
      .title {
        font-size: 14px;
        .mixinEllipsis(21px);
        margin: 10px 0 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    ::v-deep(.ant-card-body) {
      padding: 19px 19px 10px;
    }
  }
}

.activate-form {
  .code {
    position: absolute;
    right: 0;
    top: 1px;
    width: 120px;
    height: 38px;
    cursor: pointer;
  }
  .btns {
    .mixinFlex(flex-end);
    .ant-btn {
      margin-left: 10px;
    }
  }
}
.green {
  color: #3fbd2a;
}
.red {
  color: #ff4c50;
}
</style>
